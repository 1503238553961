<script lang="ts" setup>
import { themeConfig } from "@themeConfig";

// Components
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import UseZChainsMegamenu from "@/layouts/components/megamenu/UseZChainsMegamenu.vue";
import EcosystemMegamenu from "@/layouts/components/megamenu/EcosystemMegamenu.vue";
import DeveloperMegamenu from "@/layouts/components/megamenu/DeveloperMegamenu.vue";
import CommunityMegamenu from "@/layouts/components/megamenu/CommunityMegamenu.vue";

const { t } = useI18n();
const isNavbarOpen = ref(false);
const isMegamenuOpen = ref(false);
const activeDropdown = ref("");
const route = useRoute();

const config = useRuntimeConfig();
const telegramLink = config.public.socialLinksTelegram;
const twitterLink = config.public.socialLinksTwitter;
const discordLink = config.public.socialLinksDiscord;
const getZCDLink = config.public.zfExchangeLink;

const navigateTo = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const openNavBar = () => {
  isNavbarOpen.value = !isNavbarOpen.value;
};

const selectMenu = (menuId = "") => {
  isMegamenuOpen.value = !!menuId;
  activeDropdown.value = menuId;
};

watch(
  () => route.fullPath,
  () => {
    isNavbarOpen.value = false;
    selectMenu();
  }
);

const menu = computed(() => {
  return [
    {
      title: t("navbar.use_zchains"),
      to: null,
      megamenu: UseZChainsMegamenu,
      icon: null,
    },
    {
      title: t("navbar.get_zcd"),
      to: '/#the-power-of-ZCD',
      megamenu: null,
      icon: null,
    },
    {
      title: t("navbar.ecosystem"),
      to: null,
      megamenu: EcosystemMegamenu,
      icon: null,
    },
    {
      title: t("navbar.developer"),
      to: null,
      megamenu: DeveloperMegamenu,
      icon: null,
    },
    {
      title: t("navbar.community"),
      to: null,
      megamenu: CommunityMegamenu,
      icon: null,
    },
    {
      title: t("navbar.partners"),
      to: '/partners',
      megamenu: null,
      icon: null,
    },
  ];
});
</script>

<template>
  <NuxtLink
    to="/"
    class="app-logo d-flex align-center justify-center gap-x-3 h-100 mr-md-9"
  >
    <VNodeRenderer :nodes="themeConfig.app.logo" />
  </NuxtLink>
  <!-- 👉 navbar left -->
  <template v-for="item in menu" :key="item.title">
    <template v-if="'megamenu' in item && item.megamenu && !item.icon">
      <div class="dropdown h-100 mr-6">
        <a
          role="button"
          class="h-100 d-flex align-center dropbtn navbar-item"
          :class="[activeDropdown === item.title && 'is-open']"
          @mouseover="() => selectMenu(item.title)"
          @focusin="() => selectMenu(item.title)"
        >
          <span class="has-naver mr-1">{{ item.title }}</span>
          <div class="iconify d-flex align-center">
            <VIcon size="22" icon="custom-chevron-down" />
          </div>
        </a>
        <div
          class="megamenu"
          :class="[activeDropdown === item.title && 'is-selected']"
        >
          <component :is="item.megamenu" class="navbar-item" />
        </div>
      </div>
    </template>
    <template v-else-if="'to' in item && item.to && !item.icon">
      <NuxtLink
        :to="item.to"
        class="d-flex align-center gap-x-3 h-100 mr-5 navbar-item"
      >
        <span class="has-naver mr-1">{{ item.title }}</span>
      </NuxtLink>
    </template>
  </template>

  <VSpacer />

  <!-- 👉 navbar right -->
  <div class="d-flex align-center">
    <!-- <VBtn
      variant="outlined"
      color="rgba(var(--v-theme-on-background))"
      max-height="35px"
    >
      {{ $t("navbar.start_building") }}
    </VBtn>

    <VDivider vertical class="ml-5 mr-3" /> -->

    <!-- 👉 navbar right after divider -->
    <template v-for="item in menu" :key="item.title">
      <template v-if="'icon' in item && item.icon">
        <IconBtn class="navbar-item d-flex align-center">
          <VIcon :icon="item.icon" />
        </IconBtn>
      </template>
    </template>

    <!--
      <NavbarThemeSwitcher
      v-show="mdAndUp"
      />
    -->

    <!--
      <NavBarI18n
      v-show="mdAndUp"
      v-if="themeConfig.app.i18n.enable && themeConfig.app.i18n.langConfig?.length"
      :languages="themeConfig.app.i18n.langConfig"
      class="ml-1"
      />
    -->

    <IconBtn
      class="navbar-item d-flex align-center dropdown"
      @click="navigateTo(twitterLink)"
    >
      <VIcon icon="custom-twitter" />
    </IconBtn>
    <IconBtn
      class="navbar-item d-flex align-center dropdown ml-1"
      @click="navigateTo(telegramLink)"
    >
      <VIcon icon="custom-telegram" />
    </IconBtn>
    <IconBtn
      class="navbar-item d-flex align-center dropdown ml-1"
      @click="navigateTo(discordLink)"
    >
      <VIcon icon="custom-discord" />
    </IconBtn>
  </div>

  <!-- 👉 mobile menu -->
  <div class="navbar-mobile">
    <IconBtn class="d-flex align-center" @click="openNavBar">
      <VIcon :active="false" icon="custom-mobile-menu" />
    </IconBtn>
    <div v-if="isNavbarOpen" class="menu-mobile">
      <div class="ma-3 mr-6 d-flex justify-end">
        <div class="d-flex align-center" @click="openNavBar">
          <VIcon size="14" icon="custom-close" />
        </div>
      </div>
      <div class="pb-4">
        <div v-for="item in menu" :key="item.title">
          <div v-if="'megamenu' in item && item.megamenu">
            <div class="dropdown d-flex justify-center w-100 mb-3">
              <a
                role="button"
                class="dropbtn d-flex justify-center w-100"
                :class="[activeDropdown === item.title && 'is-open']"
                @mouseover="() => selectMenu(item.title)"
                @focusin="() => selectMenu(item.title)"
              >
                <span class="mr-2">{{ item.title }}</span>
                <div class="iconify d-flex align-center">
                  <VIcon size="22" icon="custom-chevron-down" />
                </div>
              </a>
              <div class="megamenu is-selected">
                <component
                  :is="item.megamenu"
                  v-show="activeDropdown === item.title"
                  @close="() => selectMenu()"
                />
              </div>
            </div>
          </div>
          <template v-else-if="'to' in item && item.to">
            <NuxtLink
              :to="item.to"
              class="d-flex justify-center w-100 mb-3"
            >
              {{ item.title }}
            </NuxtLink>
          </template>
        </div>

        <!-- Language -->
        <!--
          <div class="dropdown d-flex justify-center w-100 mb-3">
          <a
          role="button"
          class="dropbtn d-flex justify-center w-100"
          :class="[activeDropdown === 'Language' && 'is-open']"
          @mouseover="() => selectMenu('Language')"
          @focusin="() => selectMenu('Language')"
          >
          <span class="mr-2">{{ $t('navbar.language') }}</span>
          <div class="iconify d-flex align-center">
          <VIcon
          size="22"
          icon="custom-chevron-down"
          />
          </div>
          </a>
          <div class="megamenu is-selected">
          <NavBarI18n
          v-if="themeConfig.app.i18n.enable && themeConfig.app.i18n.langConfig?.length"
          v-show="activeDropdown === 'Language'"
          :languages="themeConfig.app.i18n.langConfig"
          @close="() => selectMenu()"
          />
          </div>
          </div>
        -->

        <div class="d-flex justify-center">
          <IconBtn @click="navigateTo(twitterLink)">
            <VIcon size="24" icon="custom-twitter" />
          </IconBtn>
          <IconBtn @click="navigateTo(telegramLink)">
            <VIcon size="24" icon="custom-telegram" />
          </IconBtn>
          <IconBtn @click="navigateTo(discordLink)">
            <VIcon size="24" icon="custom-discord" />
          </IconBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.dropdown .dropbtn {
  border: none;
  outline: none;
}

.navbar a:hover,
.dropdown:hover .dropbtn.is-open {
  > .iconify {
    transform: rotate(180deg) !important;
    transition: transform 150ms ease;
  }
}

.dropbtn {
  > .iconify {
    transform: rotate(0deg) !important;
    transition: transform 150ms ease;
  }
}

.megamenu {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  // width: 1020px;
  background-color: rgb(var(--v-theme-surface));
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  .megamenu-margin {
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.megamenu-right {
  display: none;
  position: absolute;
  background-color: rgb(var(--v-theme-surface));
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translate(-45%, 0) !important;

  .megamenu-margin {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.menu-mobile {
  position: absolute;
  top: variables.$layout-horizontal-nav-navbar-height;
  width: 100%;
  left: 0;
  // width: 1020px;
  background-color: rgb(var(--v-theme-surface));
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .is-selected {
  display: block;
}

.navbar-mobile {
  display: none;
}

.navbar-item {
  &:hover {
    color: rgb(var(--v-theme-primary));

    .has-naver {
      color: rgb(var(--v-theme-primary));

      &::before {
        transform-origin: left center;
        transform: scale(1, 1);
      }
    }
  }

  .has-naver {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 2px;
      width: 50%;
      transform-origin: right center;
      height: 3px;
      border-radius: 50px;
      background: rgb(var(--v-theme-primary));
      transform: scale(0, 1);
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

@media only screen and (width <= 960px) {
  .navbar-mobile {
    display: flex !important;
  }
  .navbar-item {
    display: none !important;
  }
  .megamenu {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    // width: 1020px;
    background-color: rgb(var(--v-theme-surface));
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    .megamenu-margin {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  .megamenu-right {
    display: none;
    position: absolute;
    width: 100%;
    transform: translate(0, 0) !important;
    left: 0;

    .megamenu-margin {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
}

:deep(.megamenu) {
  .megamenu-inner {
    .megamenu-block {
      .megamenu-block-list-item {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 15%;
          left: 0;
          width: 3px;
          height: 0;
          background: rgb(var(--v-theme-primary));
          border-radius: 20rem;
          transition: height 0.3s, transform 0.3s;
        }

        &:hover {
          .list-link {
            color: rgb(var(--v-theme-primary));
            transform: translateX(0.5rem);
          }

          &::before {
            height: 70%;
          }
        }

        .list-link {
          display: block;
          position: relative;
          font-family: var(--font);
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
          transition: color 0.3s, transform 0.3s;
        }
      }
    }
  }

  .list-link--comming-soon {
    background-color: rgb(var(--v-theme-primary), 0.5);
    color: white !important;
    border: 1px solid #ff0000; /* Red border */
    border-radius: 0.5rem; /* Rounded corners */
    padding: 3px 6px;
    font-size: 10px; /* Font size */
    display: inline-block; /* Make the span behave like a block element */
    cursor: default; /* Cursor style */
  }
}
</style>
